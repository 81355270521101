import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaReact, FaPython, FaDatabase, FaJava, FaJs, FaPhp, FaLinux } from 'react-icons/fa';
import {FaWindows} from "react-icons/fa";
import { SiSplunk, SiSnort } from "react-icons/si";
function ProgrammingIcons() {
  const icons = [
    { Icon: FaReact, name: 'React' },
    { Icon: FaPython, name: 'Python' },
    { Icon: FaDatabase, name: 'SQL' },
    { Icon: FaJava, name: 'Java' },
    { Icon: FaJs, name: 'JavaScript' },
    { Icon: FaPhp, name: 'PHP' },
    { Icon: FaWindows, name: 'Windows' },
    { Icon: FaLinux, name: 'Linux'},
    { Icon: SiSplunk, name: 'Splunk'},
    { Icon: SiSnort, name: 'Snort'}
    
  ];

  // Repeat the icons array to ensure seamless looping
  const repeatedIcons = [...icons, ...icons, ...icons];

  return (
    <div className="relative w-full h-16 mt-8 overflow-hidden">
      <motion.div
        className="flex absolute"
        initial={{ x: '0%' }}
        animate={{ x: '-33.33%' }}
        transition={{
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 20,
            ease: "linear",
          },
        }}
      >
        {repeatedIcons.map((icon, index) => (
          <div key={index} className="flex flex-col items-center mx-8">
            <icon.Icon size={24} className="text-white" />
            <p className="text-xs mt-1 text-white">{icon.name}</p>
          </div>
        ))}
      </motion.div>
    </div>
  );
}

function Experience() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const experiences = [
    {
      title: 'Computer Science Student',
      date: '2020-2022',
      description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M2.25 5.25a3 3 0 013-3h13.5a3 3 0 013 3V15a3 3 0 01-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 01-.53 1.28h-9a.75.75 0 01-.53-1.28l.621-.622a2.25 2.25 0 00.659-1.59V18h-3a3 3 0 01-3-3V5.25zm1.5 0v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5z" clipRule="evenodd" />
        </svg>
      )
    },
    {
      title: 'Cybersecurity Student',
      date: '2023-Current',
      description: '"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. ',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z" clipRule="evenodd" />
        </svg>
      )
    }
  ];

  return (
    <section ref={ref} className="py-10 bg-black text-white relative overflow-hidden">
      {/* Gradient dot background */}
      <div 
        className="absolute inset-0"
        style={{
          backgroundImage: `radial-gradient(#ffffff 1px, transparent 1px), radial-gradient(#ffffff 1px, transparent 1px)`,
          backgroundSize: '50px 50px',
          backgroundPosition: '0 0, 25px 25px',
          opacity: 0.10,
          maskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 100%)',
          WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 100%)',
        }}
      ></div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div 
          className="flex flex-col items-center mb-8"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.5, staggerChildren: 0.2 } },
          }}
        >
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { opacity: 1, y: 0 },
            }}
            className="flex flex-col items-center"
          >
            <div className="w-px h-12 bg-gradient-to-t from-red-500 to-transparent"></div>
            <div className="w-8 h-8 rounded-full bg-gradient-to-br from-red-500 to-orange-500 flex items-center justify-center font-bold text-white">
              <span>2</span>
            </div>
          </motion.div>
          <motion.h2
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { opacity: 1, y: 0 },
            }}
            className="text-3xl font-bold text-red-500 mt-4"
          >
            My Experience
          </motion.h2>
          
          <ProgrammingIcons />
        </motion.div>
        <div className="relative">
          <div className="absolute left-1/2 transform -translate-x-1/2 border-l-2 border-gray-700 opacity-30 h-full"></div>
          {experiences.map((exp, index) => (
            <ExperienceItem key={index} experience={exp} index={index} isLast={index === experiences.length - 1} />
          ))}
        </div>
      </div>
    </section>
  );
}

function ExperienceItem({ experience, index, isLast }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const variants = {
    hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
    visible: { opacity: 1, x: 0 },
  };
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: 0.5, delay: 0.2 }}
      className={`mb-16 flex justify-between items-center relative ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'}`}
    >
      <div className={`w-5/12 ${index % 2 === 0 ? 'text-left' : 'text-right'}`}>
        <div className="border border-gray-700 rounded-lg p-4">
          <h3 className="text-xl font-bold mb-2">{experience.title}</h3>
          <p className="text-gray-500 mb-2">{experience.date}</p>
          <p className="text-gray-400">{experience.description}</p>
        </div>
      </div>
      <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center">
        <div className="w-px h-8 bg-gray-700 opacity-100"></div>
        <div className="bg-black p-2 rounded-full text-gray-400 z-10">
          {experience.icon}
        </div>
        {!isLast && <div className="w-px h-8 bg-gray-700 opacity-30"></div>}
      </div>
      <div className="w-5/12"></div>
    </motion.div>
  );
}

export default Experience;