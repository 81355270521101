import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [sliderStyles, setSliderStyles] = useState({ width: 0, left: 0, opacity: 0 });
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navRef = useRef(null);
  const navigate = useNavigate();

  const navItems = [
    { name: 'ABOUT', path: '/' },
    { name: 'NOTES', path: '/notes' },
    { name: 'PROJECTS-TBA', path: '/projects' },
    { name: 'CLI', path: '/cli' },
    //{ name: 'CALENDAR', path: '/calendar' }
  ];

  useEffect(() => {
    if (navRef.current) {
      const firstButton = navRef.current.children[0];
      setSliderStyles({
        width: firstButton.offsetWidth,
        left: firstButton.offsetLeft,
        opacity: 0,
      });
    }
  }, []);

  useEffect(() => {
    if (navRef.current && hoveredIndex !== null) {
      const button = navRef.current.children[hoveredIndex];
      setSliderStyles({
        width: button.offsetWidth,
        left: button.offsetLeft,
        opacity: 1,
      });
    } else if (hoveredIndex === null) {
      setSliderStyles(prev => ({ ...prev, opacity: 0 }));
    }
  }, [hoveredIndex]);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== 'undefined') {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY) {
          // Scrolling down
          setIsVisible(false);
        } else {
          // Scrolling up
          setIsVisible(true);
        }
        // Update last scroll position
        setLastScrollY(currentScrollY);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      // Cleanup
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.header
          className="p-2 bg-black shadow-md sticky top-0 w-full z-50"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          transition={{ duration: 0.3 }}
        >
          <nav ref={navRef} className="flex justify-center items-center relative">
            {navItems.map((item, index) => (
              <button
                key={item.name}
                className={`
                  px-3 py-2 text-sm font-medium relative z-10
                  transition-colors duration-300
                  ${index === hoveredIndex ? 'text-white' : 'text-gray-400'}
                  hover:text-gray-200
                `}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => handleNavigation(item.path)}
              >
                {item.name}
              </button>
            ))}
            <motion.div
              className="absolute h-8 bg-gray-700 rounded-full bottom-1"
              initial={false}
              animate={sliderStyles}
              transition={{
                type: 'spring',
                stiffness: 500,
                damping: 30,
                opacity: { duration: 0.2 }
              }}
            />
          </nav>
        </motion.header>
      )}
    </AnimatePresence>
  );
}